#chakra-modal-drawer-pending-studies {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #60606c;
  box-shadow: -3px -11px 19px rgba(120, 120, 120, 0.1);
  border-radius: 8px 0px 0px 8px;
  width: 100%;
  height: fit-content;
  min-height: 350px;
}

.tab-selected {
  font-weight: 600 !important;
  border-bottom: 4px solid #e56000 !important;
  color: #ffff !important;
  background-color: #393940 !important;
  height: 44px;
}

.react-tab {
  font-family: Mulish;
  font-weight: 400 !important;
  display: flex !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: 16px;
  background: #302f37;
  gap: 10px;
  border: 0px;
  box-shadow: none;
  margin: 0px;
  color: #ffff;
  display: inline-block;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}
