.NewDatePicker {
  width: 100%;
}

.NewDatePicker .ReactModal__Overlay {
  opacity: 0;
  transform: translateX(800px);
  transition: all 500ms ease-in-out;
  font-family: 'Mulish';
}
.NewDatePicker .ReactModal__Content::-webkit-scrollbar {
  width: 8px;
}
.NewDatePicker .ReactModal__Content::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 17px;
  height: 8px;
}
.NewDatePicker .ReactModal__Content::-webkit-scrollbar-thumb {
  background-color: #60606c;
  border-radius: 17px;
  height: 8px;
}

.NewDatePicker .ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.NewDatePicker .ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(100px);
}

.NewDatePicker .react-datepicker__month-container {
  background: rgba(57, 57, 64);
}

.react-datepicker {
  overflow: hidden;
  border-radius: 8px;
}

.NewDatePicker .react-datepicker__day {
  color: white;
  font-family: 'Mulish';
}

.NewDatePicker .react-datepicker__day:hover {
  background-color: #26262c;
}

.NewDatePicker .react-datepicker__header {
  background: rgba(57, 57, 64);
  border-bottom: 0px;
  font-family: 'Mulish';
}

.NewDatePicker .react-datepicker__day-name {
  color: white;
}

.NewDatePicker .react-datepicker__day-names {
}

.NewDatePicker .react-datepicker__current-month {
  color: #e56000;
  padding-bottom: 18px;
  padding-top: 18px;
  font-family: 'Mulish';
}

.NewDatePicker .react-datepicker__current-month::first-letter {
  text-transform: uppercase;
}

.NewDatePicker .react-datepicker__navigation {
  margin-top: 20px;
}

.NewDatePicker .react-datepicker {
  border: 0px;
  font-family: 'Mulish';
}

.NewDatePicker .react-datepicker__triangle {
  display: none;
}

.NewDatePicker .react-datepicker__day--disabled {
  color: #474a43;
}

.NewDatePicker .react-datepicker__day--keyboard-selected {
  background: #ff6b00;
}

.NewDatePicker .react-datepicker__day--selected:hover {
  background-color: #d45a02;
}

.NewDatePicker .react-datepicker__day--keyboard-selected:hover {
  background-color: #d45a02;
}

.NewDatePicker .react-datepicker__day-name,
.NewDatePicker .react-datepicker__day,
.NewDatePicker .react-datepicker__time-name {
  text-transform: uppercase;
}
