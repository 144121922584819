.videoEssencialSkill {
  border-radius: 8px;
  border: 1px solid #e56000;
  height: 100%;
  display: flex;
  max-width: 870px;
  width: 100%;
  max-height: 516px;
  min-width: 100% !important;
  min-height: 100% !important;
  object-fit: contain;
  object-position: center;
}
